<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Asosiy vositalar ruyhati</h3>
          </div>
        </div>
        <div class="card-body">
          <div>
            <v-data-table
              :loading="isLoading"
              :headers="headers"
              :items="getMainAssets"
              :items-per-page="5"
              class="elevation-1"
              disable-pagination
            >
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      headers: [
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'type',
          value: 'type'
        },
        {
          text: 'old_period_usage',
          value: 'old_period_usage'
        },
        {
          text: 'new_period_usage',
          value: 'new_period_usage'
        },
        {
          text: 'old_remain_value',
          value: 'old_remain_value'
        },
        {
          text: 'new_remain_value',
          value: 'new_remain_value'
        },
        {
          text: 'oper_date',
          value: 'oper_date'
        }
      ]
    }
  },
  created() {
    this.$store.dispatch('annualRemark')
  },
  computed: {
    getMainAssets() {
      var data = this.$store.state.requests.annualRemark
      if (data.length !== 0) {
        console.log(data)
        data = data.find(
          (x) => x.id == this.$route.params.id
        ).mainassetspriceremark_set
      }
      return data
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    }
  },
  // SET_BREADCRUMB
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: ' Asosiy vositalar' }
    ])
  }
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  min-height: 300px;
  width: 100%;
}
</style>
